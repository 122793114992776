<template>
  <section id="summary__patient">
    <v-col>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="createSummaryPDF" color="primary" v-if="!loading" text>
            <v-icon>mdi-file-pdf-outline</v-icon
            >{{ $t('summary.datatable.downloadPDF') }}</v-btn
          >
        </v-card-title>
        <v-data-table
          ref="summaryTable"
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          hide-default-footer
          disable-pagination
          dense
        >
          <template v-slot:loading>
            {{
              errorMessage !== ''
                ? errorMessage
                : $t('summary.datatable.loadingMessage')
            }}
          </template>

          <template v-slot:no-data>
            {{
              errorMessage !== ''
                ? errorMessage
                : $t('summary.datatable.noData')
            }}
          </template>

          <template v-slot:item.day="{ item }">
            <span class="timeslot">
              {{ item.from_real_date | dateDay }}
              &#8594; {{ item.to_real_date | dateDay }}
            </span>
          </template>

          <template v-slot:item.date="{ item }">
            <span class="timeslot">
              {{ item.from_real_date | date }}
              &#8594; {{ item.to_real_date | date }}
            </span>
          </template>

          <template v-slot:item.sleep="{ item }">
            <ul class="timeslot">
              <li v-for="s in item.sleep" :key="s[0]">
                {{ s[0] | hour }} &#8594; {{ s[1] | hour }}
              </li>
            </ul>
          </template>

          <template v-slot:item.raw_answers.GRAPH.nap="{ item }">
            <ul class="timeslot">
              <li v-for="s in item.naps" :key="s[0]">
                {{ s[0] | date }} &#8594; {{ s[1] | date }} ({{
                  s[0] | hour
                }}
                &#8594; {{ s[1] | hour }})
              </li>
            </ul>
          </template>

          <template v-slot:item.bedtime="{ item }">
            {{ item.bedtime | hour }}
          </template>

          <template v-slot:item.wakeup="{ item }">
            {{ item.wakeup | hour }}
          </template>

          <template v-slot:item.sleep_quality="{ item }">
            <span v-if="item.sleep_quality === 'NA'" class="no__data">
              {{ $t('summary.datatable.nc') }}
            </span>
            <span v-else-if="item.sleep_quality === null" class="no__data">
            </span>
            <span v-else
              ><meter :value="item.sleep_quality" min="0" max="10">
                {{ item.sleep_quality }}
              </meter>
              {{ item.sleep_quality }}</span
            >
          </template>

          <template v-slot:item.wake_quality="{ item }">
            <span v-if="item.wake_quality === 'NA'" class="no__data">
              {{ $t('summary.datatable.nc') }}
            </span>
            <span v-else-if="item.wake_quality === null" class="no__data">
            </span>
            <span v-else
              ><meter :value="item.wake_quality" min="0" max="10">
                {{ item.wake_quality }}
              </meter>
              {{ item.wake_quality }}</span
            >
          </template>

          <template v-slot:item.day_shape="{ item }">
            <span v-if="item.day_shape === 'NA'" class="no__data">
              {{ $t('summary.datatable.nc') }}
            </span>
            <span v-else-if="item.day_shape === null" class="no__data"> </span>
            <span v-else
              ><meter :value="item.day_shape" min="0" max="10">
                {{ item.day_shape }}
              </meter>
              {{ item.day_shape }}</span
            >
          </template>

          <template v-slot:item.note="{ item }">
            <span v-if="item.note === 'NA'" class="no__data"> </span>
            <span v-else>{{ item.note }} </span>
          </template>

          <template v-slot:item.meds="{ item }">
            <span v-if="item.meds.length == 0" class="no__data"> </span>
            <span v-else v-for="med in item.meds" :key="med">
              <span style="display: block">{{ med }}</span>
            </span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </section>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { mapGetters } from 'vuex'

export default {
  name: 'InsoluxSummary',
  props: {
    errorMessage: {
      required: false,
      type: String,
      default: '',
    },
    items: {
      required: true,
      type: Array,
    },
    loading: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(['user']),
    headers() {
      return [
        {
          text: this.$t('summary.agenda.headers.day'),
          value: 'day',
        },
        {
          text: this.$t('summary.agenda.headers.date'),
          value: 'date',
        },
        {
          text: this.$t('summary.agenda.headers.bedtime'),
          value: 'bedtime',
        },
        {
          text: this.$t('summary.agenda.headers.sleep'),
          value: 'sleep',
        },
        {
          text: this.$t('summary.agenda.headers.wakeup'),
          value: 'wakeup',
        },
        {
          text: this.$t('summary.agenda.headers.naps'),
          value: 'raw_answers.GRAPH.nap',
        },
        {
          text: this.$t('summary.agenda.headers.sleepQuality'),
          value: 'sleep_quality',
        },
        {
          text: this.$t('summary.agenda.headers.wakeQuality'),
          value: 'wake_quality',
        },
        {
          text: this.$t('summary.agenda.headers.dayShape'),
          value: 'day_shape',
        },
        {
          text: this.$t('summary.agenda.headers.comments'),
          value: 'note',
        },
        {
          text: this.$t('summary.agenda.headers.meds'),
          value: 'meds',
        },
      ]
    },
    filteredItems() {
      let cleaned_items = []

      this.items.forEach(item => {
        if (item.filled) {
          cleaned_items.push(item)
        }
      })
      return cleaned_items
    },
  },
  filters: {
    dateDay: function(str) {
      if (!str) return ''
      const day = new Date(str).toLocaleDateString(
        navigator.language || 'fr-FR',
        {
          weekday: 'long',
        }
      )
      return day[0].toUpperCase() + day.slice(1)
    },
    date: function(str) {
      if (!str) return ''
      return new Date(str).toLocaleDateString(navigator.language || 'fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },
    hour: function(str) {
      if (!str) return ''
      let date = new Date(str)
      return date.getHours() + 'h' + String(date.getMinutes()).padStart(2, '0')
    },
  },
  methods: {
    hours(sleep) {
      let result = ''
      let hours = Object.keys(sleep).map(function(key) {
        if (sleep[key]) {
          return [sleep[key]]
        }
      })
      hours.forEach(hour => {
        if (hour[0].length) {
          result = `${this.$options.filters.hour(
            hour[0][0]
          )} - ${this.$options.filters.hour(hour[0][1])}`
        }
      })
      return result
    },
    dates(sleep) {
      let result = ''
      let dates = Object.keys(sleep).map(function(key) {
        if (sleep[key]) {
          return [sleep[key]]
        }
      })
      dates.forEach(date => {
        if (date[0].length) {
          result = `${this.$options.filters.date(
            date[0][0]
          )} > ${this.$options.filters.date(date[0][1])}`
        }
      })
      return result
    },
    displayNaps(naps) {
      let result = []
      naps.forEach(nap => {
        result +=
          `${this.$options.filters.date(nap[0])}` +
          ' > ' +
          `${this.$options.filters.date(nap[1])}` +
          ' (' +
          `${this.$options.filters.hour(nap[0])}` +
          ' - ' +
          `${this.$options.filters.hour(nap[1])})\n`
      })
      return result
    },
    displaySleepsHours(sleeps) {
      let result = []
      sleeps.forEach(sleep => {
        result +=
          `${this.$options.filters.hour(sleep[0])}` +
          ' - ' +
          `${this.$options.filters.hour(sleep[1])}` +
          '\n'
      })
      return result
    },
    createSummaryPDF() {
      var source = this.$refs['summaryTable']
      let rows = []
      // TODO: Translate
      let columnHeader = [
        this.$t('summary.agenda.headers.day'),
        this.$t('summary.agenda.headers.date'),
        this.$t('summary.agenda.headers.bedtime'),
        this.$t('summary.agenda.headers.sleep'),
        this.$t('summary.agenda.headers.wakeup'),
        this.$t('summary.agenda.headers.naps'),
        this.$t('summary.agenda.headers.sleepQuality'),
        this.$t('summary.agenda.headers.wakeQuality'),
        this.$t('summary.agenda.headers.dayShape'),
        this.$t('summary.agenda.headers.comments'),
        this.$t('summary.agenda.headers.meds'),
      ]
      // TODO: add summaray label for pdf title
      let pdfName = `${this.$t('summary.title.summaryFilename')}_${
        this.user.username
      }`

      source.items.forEach(element => {
        var tmp = [
          this.$options.filters.dateDay(element.from_real_date) +
            ' > ' +
            this.$options.filters.dateDay(element.to_real_date),
          this.$options.filters.date(element.from_real_date) +
            ' > ' +
            this.$options.filters.date(element.to_real_date),
          this.$options.filters.hour(element.bedtime),
          this.displaySleepsHours(element.sleep),
          this.$options.filters.hour(element.wakeup),
          this.displayNaps(element.naps),
          !['NA', 'NC'].includes(element.sleep_quality)
            ? `${element.sleep_quality}/10`
            : element.sleep_quality,
          !['NA', 'NC'].includes(element.wake_quality)
            ? `${element.wake_quality}/10`
            : element.wake_quality,
          !['NA', 'NC'].includes(element.day_shape)
            ? `${element.day_shape}/10`
            : element.day_shape,
          !['NA', 'NC'].includes(element.note) ? element.note : element.note,
          element.meds,
        ]
        rows.push(tmp)
      })

      const doc = new jsPDF('landscape')

      // pdf properties
      doc.setProperties({
        title: this.$t('summary.title.main', {
          patientName: this.user.username,
        }),
        subject: 'Oniros',
        author: 'Oniros Application',
      })

      const logoSrc = require('@/assets/img/logo-oniros.png')
      let logo = new Image()
      logo.src = logoSrc

      doc.addImage(logo, 'PNG', 5, 5, 28, 5)

      doc.text(
        20,
        20,
        this.$t('summary.title.main', { patientName: this.user.username })
      )

      doc.autoTable({
        columns: columnHeader,
        body: rows,
        startY: 30,
        styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        headStyles: {
          fillColor: [222, 121, 29],
          fontSize: 6,
        },
        fontSize: 7,
        columnStyles: { text: { cellWidth: 'auto' } },
      })

      const pages = doc.internal.getNumberOfPages()
      const pageWidth = doc.internal.pageSize.width
      const pageHeight = doc.internal.pageSize.height

      doc.setFontSize(7)

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2
        let verticalPos = pageHeight - 10
        let today = new Date().toLocaleDateString()
        doc.setPage(j)
        // TODO: check why align param not working for now
        doc.text(
          `Oniros - ${today} - page ${j} / ${pages}`,
          horizontalPos,
          verticalPos,
          'right'
        )
      }

      doc.save(pdfName + '.pdf')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  .v-card__title::v-deep {
    display: flex;
    grid-gap: 15px;

    i {
      font-size: 30px;
    }
  }

  .v-data-table::v-deep {
    .no__data {
      font-style: italic;
      color: grey;
    }
  }
}

.timeslot {
  white-space: nowrap;
  padding-left: 0;
  list-style-type: none;
}
</style>
